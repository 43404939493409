import "../App.css";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../components/NavBar/Head";
import { BsDownload, BsEye, BsPen, BsTrash3, BsShare } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { IoMdClose } from 'react-icons/io'; 
import { renderToStaticMarkup } from "react-dom/server";
import DataTables from "../components/DataTables";
import axios from "axios";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modals from "../components/Modal";
import { IoDuplicateOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { paymentModes } from "../Utils/Constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";

import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import { ToastContainer, toast } from "react-toastify";
import { endOfYear, startOfYear, subYears } from "date-fns";
import MultiRange from "../components/CustomRangeSlider/MultiRange";
import { FETCH_INVOICE_SUCCESS } from "../store/Invoice/InvoiceActionTypes";
import InvoiceVoucherModal from "../Utils/InvoiceVoucherModal";
import AllTemplate from "../components/Invoice/AllTemplate";
import SelectInvoice from "../components/Invoice/SelectInvoice";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userInfo);
  const { theme } = useSelector((state) => state);
  const checkRole = userInfo?.user_data?.role;
  const [selectedItems, setSelectedItems] = useState([]);
  const [additionalFilter, setAdditionalFilter] = useState({});
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const formatDate = userInfo?.account_preferences?.date_formate;
  const [isDeleting, setIsDeleting] = useState(false);
  const [show1, setShow1] = useState(false);  
  const [status1, setStatus1] = useState("paid"); 
  const [userStatus1, setUserStatus1] = useState(""); 
  const [selectedDate1, setSelectedDate1] = useState(moment().format("YYYY-MM-DD")); 
  const [description1, setDescription1] = useState(""); 
  const [showModal1, setShowModal1] = useState(false);
  const [downloadId, setDownloadId] = useState()
  const [isHovered, setIsHovered] = useState(false); 
  const [showEmailModal, setShowEmailModal] = useState(false); 

  const [file, setFile] = useState(null); 
  const fileInputRef = useRef(null);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [isShow, setIsShow] = useState({
    open: false,
    id: "",
    requestType: "",
  });
  const [isShowModel, setIsShowModel] = useState({
    open: false,
    id: "",
    requestType: "",
  });
  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setAdditionalFilter({
      ...additionalFilter,
      dateRange: `${moment(ranges.selection.startDate).format(
        "YYYY-MM-DD"
      )} | ${moment(ranges.selection.endDate).format("YYYY-MM-DD")}`,
    });
  };
  const [deleteInvoiceAction, setDeleteInvoiceAction] = useState({
    showConfirmationModal: false,
  });

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: "invoice_no",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,
      visible:
        checkRole?.invoice?.includes("delete") ||
        checkRole?.invoice?.includes("Delete"),
      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "paid_to",
      title: "Bill To",
      class: "text-left align-middle",
    },
    {
      data: "date",
      class: "text-left align-middle",
      title: "Invoice Date",
      render: function (data, type, row) {
        return moment(row.date).format(
          `${
            formatDate === null || formatDate === undefined
              ? "DD MMM, YYYY"
              : formatDate
          }`
        );
      },
    },
    {
      data: "voucher_no",
      class: "text-left align-middle",
      title: "Invoice Number",
    },
    {
      data: "total_amount",
      class: "text-left align-middle",
      title: "Amount",
      render: function (data, type, row) {
        return `₹${row?.total_amount}`;
      },
    },
    // Status Section Commented By Pulkit
    // {
    //   data: null,
    //   class: "text-left align-middle",
    //   title: "Status",
    //   render: function(data, type, row) {
    //     let buttonToRender;
    //     if (row.total_amount <= 1000) { 
    //       buttonToRender = (
    //         <Button 
    //           style={{ padding: "2px 5px", backgroundColor: "#def8dc", color: "#4e9440", fontSize: "0.92rem" }}
    //           variant="light"
    //           className="mx-1"
    //           data-id={"paid"}
    //           data-action="handleStatus"
    //           onClick={() => handleStatus("paid")}
    //         >
    //           Paid
    //         </Button>
    //       );
    //     } else if (row.total_amount >1000 && !row.total_amount<=2000) { 
    //       buttonToRender = (
    //         <Button 
    //           style={{ padding: "2px 5px", backgroundColor: "#fef4d8", color: "#a68651", fontSize: "0.92rem" }}
    //           variant="light"
    //           className="mx-1"
    //           data-id={"pending"}
    //           data-action="handleStatus"
    //           onClick={() => handleStatus("pending")}
    //         >
    //           Pending
    //         </Button>
    //       );
    //     } else if (row.total_amount>2000) { 
    //       buttonToRender = (
    //         <Button 
    //           style={{ padding: "2px 5px", backgroundColor: "#f8d3d6", color: "#b22d2d", fontSize: "0.92rem" }}
    //           variant="light"
    //           data-id={"cancelled"}
    //           data-action="handleStatus"
    //           onClick={() => handleStatus("cancelled")}
    //         >
    //           Cancelled
    //         </Button>
    //       );
    //     } else {
    //       buttonToRender = null;
    //     }
    
    //     return renderToStaticMarkup(
    //       <>
    //         {buttonToRender}
    //       </>
    //     );
    //   },
    // },
    {
      data: null,
      class: "text-left  align-middle",
      title: "Actions",
      width: "250px",
      orderable: false,
      render: function (data, type, row) {
        return renderToStaticMarkup(
          <div className="text-right">
            {(checkRole?.invoice?.includes("view") ||
              checkRole?.invoice?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="View invoice"
                variant="light"
                size="sm"
                data-id={row.id}
                // data-action="view"
                className="btn-icon mx-1"
                onClick="handleButtonClick('sdf')"
                data-action="handleView"
              >
                <BsEye size={15} /> 
              </Button>
            )}
            {(checkRole?.invoice?.includes("edit") ||
              checkRole?.invoice?.includes("Edit")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit invoice"
                variant="light"
                size="sm"
                className="btn-icon mx-1"
                data-id={row.id}
                // data-action="edit"
                data-action="handleEdit"
                onClick={() => handleEdit(row.id)}
              >
                <BsPen size={15} />
              </Button>
            )}
            {(checkRole?.invoice?.includes("create") ||
              checkRole?.invoice?.includes("Create")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Create duplicate invoice"
                variant="light"
                size="sm"
                data-id={row.id}
                data-action="handleDuplicate"
                className="btn-icon mx-1"
              >
                <IoDuplicateOutline size={15} />
              </Button>
            )}
            {(checkRole?.invoice?.includes("view") ||
              checkRole?.invoice?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                // Commented By Pulkit
                // data-tooltip-content="Share invoice"
                data-tooltip-content="Download invoice"
                variant="light"
                size="sm"
                className="me-2 btn-icon mx-1"
                data-id={row.id}
                data-action="downloadAsPdf"
              >
              {/* Commented By Pulkit */}
                {/* <BsShare size={15} /> */} 
                <BsDownload size={15} />
              </Button>
            )}
            {(checkRole?.invoice?.includes("delete") ||
              checkRole?.invoice?.includes("Delete")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Delete invoice"
                variant="light"
                size="sm"
                className="btn-icon text-danger mx-1"
                data-id={row.id}
                data-action="confirmBeforeDeletion"
              >
                <BsTrash3 size={15} />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  // sending invoice vis email
  const formik = useFormik({
    initialValues: {
      emailTo: '',
      subject: '',
    },
    validationSchema: Yup.object({
      emailTo: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      subject: Yup.string().required('Subject is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("Value>>>>>>>>>>>>>>>>>",values)
      setShowEmailModal(false);
      // try {
      //   const response = await axios.post('/share-invoice-on-mail', {
      //     email_to: values.emailTo,
      //     subject: values.subject,
      //     downloadId, // Pass the necessary data like downloadId
      //   });
      //   console.log('Email sent successfully:', response.data);
      //   setShowEmailModal(false);
      // } catch (error) {
      //   console.error('Error sending email:', error);
      // } finally {
      //   setSubmitting(false);
      // }
    },
  });


  const handleStatus = (statusValue) => {
    if (statusValue === "paid" || statusValue === "pending" || statusValue === "cancelled") {
      setUserStatus1(statusValue)
      setShow1(true)
    }
  };
  const handleClose1 = () => setShow1(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; 
  
    if (selectedFile) {
      const reader = new FileReader(); 
  
      reader.onloadend = () => {
        const base64File = reader.result; 
        setFile(base64File); 
      };
  
      reader.readAsDataURL(selectedFile); 
    }
  };
   const handleConfirm = () => {
    console.log("User Data:");
    console.log("Status:", status1);
    console.log("Date:", selectedDate1);
    console.log("Description:", description1);
    console.log("File (Base64):", file);
    
    handleClose1(); 
  };

  // sending invoice via email
  // const handleSendEmail = async () => {
  //   try {
  //     const response = await axios.post('/share-invoice-on-mail', {
  //       email_to: emailTo,
  //       subject: subject,
  //       downloadId, 
  //     });
  //     console.log('Email sent successfully:', response.data);
  //     setShowEmailModal(false);
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //   }
  // };
  const handleDateChange = (e) => {
    const formattedDate = moment(e.target.value).format("YYYY-MM-DD");
    setSelectedDate1(formattedDate);
  };

  const handleShow2 = () => setShowModal1(true);
  const handleClose2 = () => setShowModal1(false);

  const handleView = (id) => {
    console.log("Id from view clicked>>>>>>>>>>>>>>>>",id)
    setIsShow({
      ...isShow,
      open: true,
      id,
      requestType: "view",
    });
  };
  const handleEdit = (id) => {
    setIsShow({
      ...isShow,
      open: true,
      id,
      requestType: "edit",
      needTemplateChange: false,
    });
  };
  const downloadAsPdf = (id) => {
    setDownloadId(id)
    setShowModal1(true)
    // navigate(`view/${id}`, {
    //   state: {
    //     download: "true",
    //     templateId:"download"
    //   },
    // });
  };
  const handleDuplicate = (id, requestType = false) => {
    navigate(
      `/invoices/create-duplicate-invoice/${
        requestType ? selectedItems[0] : id
      }`
    );
  };

  const confirmBeforeDeletion = (id, requestType = false) => {
    setDeleteInvoiceAction({
      ...deleteInvoiceAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };

  const deleteInvoice = async () => {
    setIsDeleting(true);

    try {
      const res = await axios.post(
        `${
          process.env.REACT_APP_API_BASE_URL +
          process.env.REACT_APP_DELETE_INVOICE
        }`,
        {
          ids: deleteInvoiceAction.idsToDelete,
        },
        {
          headers: {
          Authorization: `Bearer ${userInfo?.token}`, 
          Accept: "application/json",
          }
        }
        ,
      );

      if (res?.status === 200) {
        dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
        setDeleteInvoiceAction({
          ...deleteInvoiceAction,
          showConfirmationModal: false,
          idsToDelete: [],
        });

        toast.success(
          checkedCheckBoxes.length > 1
            ? "Invoices deleted successfully"
            : "Invoice deleted successfully",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        checkAll.checked = false;
        setTimeout(() => setIsDeleting(false), 2000);
      }
    } catch (error) {
      setIsDeleting(false);
      setShowPopup({
        ...showPopup,
        is: true,
        msg:
          error?.message || error?.response?.data?.error || error?.data?.error,
      });
    }
  };

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        key: "thisYear",
        label: "This Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() : now.getFullYear() - 1;
          return {
            startDate: new Date(startYear, 3, 1), 
            endDate: new Date(startYear + 1, 2, 31), 
          };
        },
      },
      {
        key: "pastYear",
        label: "Past Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() - 1 : now.getFullYear() - 2;
          return {
            startDate: new Date(startYear, 3, 1), 
            endDate: new Date(startYear + 1, 2, 31), 
          };
        },
      },
    ]),
  ].splice(2, 12);

  return (
    <>
      <Head title="All Invoices">
        {(checkRole?.invoice?.includes("delete") ||
          checkRole?.invoice?.includes("Delete")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">
                {checkedCheckBoxes.length > 1
                  ? "Click to delete invoices"
                  : "Click to delete invoice"}
              </Tooltip>
            }
          >
            <Button
              className="btn btn-danger btn-sm me-1"
              style={
                checkedCheckBoxes.length
                  ? { display: "block" }
                  : { display: "none" }
              }
              id="multipleDeleteBtn"
              onClick={() => confirmBeforeDeletion("", 1)}
            >
              {checkedCheckBoxes.length > 1
                ? "Delete Invoices"
                : "Delete Invoice"}
            </Button>
          </OverlayTrigger>
        )}
        {(checkRole?.invoice?.includes("create") ||
          checkRole?.invoice?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">
                Click to create duplicate invoice
              </Tooltip>
            }
          >
            <Button
              variant="primary"
              className="btn-sm me-1"
              style={
                checkedCheckBoxes.length === 1
                  ? { display: "block" }
                  : { display: "none" }
              }
              id="duplicateInvoice"
              onClick={() => handleDuplicate("", 1)}
            >
              Create Duplicate Invoice
            </Button>
          </OverlayTrigger>
        )}
        {(checkRole?.invoice?.includes("create") ||
          checkRole?.invoice?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">Click to create new Invoice</Tooltip>
            }
          >
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => navigate("/create-new-invoice")}
            >
              Create new Invoice
            </Button>
          </OverlayTrigger>
        )}
      </Head>
      <Card className="border-0">
        <Card.Header className="row d-flex justify-content-around align-items-center g-0">
          <Col>Filters</Col>

          <Col xs="auto" className="me-3 position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select the amount range
                </Tooltip>
              }
            >
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    requestEvent: e.target.value,
                    amountRange: e.target.value,
                  })
                }
              >
                <option value="">Amount Range</option>
                <option value="0 | 1000">Less Then ₹1,000</option>
                <option value="1000 | 2500">Between ₹1,000 - ₹2,500</option>
                <option value="2500 | 5000">Between ₹2,500 - ₹5,000</option>
                <option value="5000 | 10000">Between ₹5,000 - ₹10,000</option>
                <option value="custom">Custom Range</option>
              </Form.Select>
            </OverlayTrigger>
          </Col>

          {additionalFilter?.requestEvent === "custom" ? (
            <Col xs="auto" className="me-4 ms-2">
              <MultiRange
                min={200}
                max={2000}
                amountRange={(value) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    amountRange: `${value?.ranges[1]} | ${value?.ranges[2]}`,
                  })
                }
              />
            </Col>
          ) : null}

          <Col xs="auto" className=" position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select the date range
                </Tooltip>
              }
            >
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  size="sm"
                >
                  {`${moment(dateRange[0].startDate).format(
                    "DD MMM, YYYY"
                  )} - ${moment(dateRange[0].endDate).format("DD MMM, YYYY")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 overflow-hidden">
                  <Dropdown.Item className="p-0 ">
                    <DateRangePicker
                      ranges={dateRange}
                      onChange={handleSelect}
                      editableDateInputs={true}
                      staticRanges={staticRanges}
                      maxDate={new Date()}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </OverlayTrigger>
          </Col>
        </Card.Header>
        <Card.Body>
          <DataTables
            url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_INVOICE_LIST}`}
            columns={columns}
            defaultSortedColumnIndex={4}
            dispatchCallType={FETCH_INVOICE_SUCCESS}
            downloadAsPdf={downloadAsPdf}
            confirmBeforeDeletion={confirmBeforeDeletion}
            setSelectedItems={setSelectedItems}
            additionalFilter={additionalFilter}
            handleDuplicate={handleDuplicate}
            handleView={handleView}
            handleEdit={handleEdit}
            handleStatus={handleStatus}
          />
        </Card.Body>
        {isShow.requestType !== "edit" && (
          <ToastContainer style={{ top: "60px"}} />
        )}
      </Card>
      <>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header className="border-0 m-auto">
          <h6 className="m-0 text-capitalize">{userStatus1} Status</h6>
        </Modal.Header>
        <Modal.Body>
          <Form>
              <Form.Label className="mb-0" htmlFor="date">Date</Form.Label>
              <Form.Group className="mb-3">
              <Form.Control
                name="date"
                type="date"
                value={selectedDate1}
                onChange={handleDateChange} 
              />
            </Form.Group>
              <Form.Label className="mb-0" htmlFor="status1">Status</Form.Label>
              <Form.Group className="mb-3">
              <Form.Select
                name="status1"
                value={status1}
                onChange={(e) => setStatus1(e.target.value)}
              >
                <option value="paid">Paid</option>
                <option value="pending">Pending</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
              <Form.Label className="mb-0" htmlFor="description">Description</Form.Label>
              <Form.Group className="mb-3">
              <Form.Control 
                as="textarea" 
                rows={3}
                name="description" 
                value={description1} 
                onChange={(e) => setDescription1(e.target.value)} 
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload file</Form.Label>
            <Form.Control 
            
              type="file" 
              onChange={handleFileChange} 
              ref={fileInputRef}
            />
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    {/* Sharing invoice popup */}
    <Modal show={showModal1} centered onHide={handleClose2}>
        <Modal.Header className="border-0" style={{ position: 'relative' }}>
          <h6 style={{ margin: '0 auto', textAlign: 'center' }}>
            Download & Share
          </h6>
          <Button
            onClick={handleClose2}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              position: 'absolute',
              right: '15px',
              top: '10px',
              backgroundColor: '#f0f0f0',
              borderRadius: '50%',
              width: '35px',
              height: '35px',
              padding: '0',
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: isHovered ? 'none' : '0px 1px 1px rgba(0, 0, 0, 0.1)',
            }}
          >
            <IoMdClose size={20} style={{ color: '#606060' }} /> 
          </Button>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center',display:"flex",justifyContent:"space-around" }}>
          <div style={{ marginBottom: '20px' }}>
            <div
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: '#f0f0f0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                cursor:"pointer",
              }}
              onClick={() => {
                navigate(`view/${downloadId}`, {
                    state: {
                      download: "true",
                      templateId:"download"
                    },
                  });
                handleClose2(); 
              }}
            >
              <BsDownload size={30}  style={{ color: '#28a745' }} />
            </div>
            <Form.Text>Download</Form.Text>
          </div>
          <div>
            <div
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: '#f0f0f0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                cursor:"pointer"
              }}
              onClick={() => {
                setShowEmailModal(true);
                handleClose2(); 
                }} 
            >
              <MdOutlineEmail size={30} style={{ color: '#EA4335' }} />
            </div>
            <Form.Text>Share via Email</Form.Text>
          </div>
        </Modal.Body>
    </Modal>  
     {/* Email Modal */}
   <Modal show={showEmailModal} centered onHide={() => setShowEmailModal(false)}>
        <Modal.Header className="border-0" style={{ position: 'relative' }}>
          <h6 className="m-auto">Share via Email</h6>
          <div
            onClick={() => setShowEmailModal(false)}
            style={{
              position: 'absolute',
              right: '15px',
              top: '10px',
              backgroundColor: 'transparent',
              width: '35px',
              height: '35px',
              padding: '0',
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <IoMdClose size={20} style={{ color: '#606060' }} /> 
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="emailTo">
              <Form.Label>Email To</Form.Label>
              <Form.Control
                type="email"
                name="emailTo"
                value={formik.values.emailTo}
                onChange={formik.handleChange}
                isInvalid={formik.touched.emailTo && !!formik.errors.emailTo}
                placeholder="Enter recipient's email"
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.emailTo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="subject" className="mt-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                isInvalid={formik.touched.subject && !!formik.errors.subject}
                placeholder="Enter email subject"
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.subject}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-3" variant="primary" type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? 'Sending...' : 'Send'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>


      <Modal show={deleteInvoiceAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you sure you want to delete the selected{" "}
          {checkedCheckBoxes.length > 1 ? "invoices" : "invoice"} ?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setDeleteInvoiceAction({
                ...deleteInvoiceAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            disabled={isDeleting}
            onClick={() => {
              deleteInvoice();
            }}
          >
            {isDeleting ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ height: "21px", width: "21px" }}
                ></div>
              </div>
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>


      {/**Show message for Error */}
      <Modals showModal={showPopup} handleClose={handlePopupClose} />

      <InvoiceVoucherModal
        isShow={isShow}
        setIsShow={setIsShow}
        setFor="invoice"
        body={
          isShow?.needTemplateChange ? (
            <>
            <AllTemplate setIsShow={setIsShow} isShow={isShow} />
            </>
          ) : (
            <>
              <SelectInvoice
                theme={
                  isShow?.values?.template_id
                    ? isShow?.values?.template_id
                    : theme.InvoiceTemplate
                }
                id={isShow?.id}
                requestType={isShow?.requestType}
                setIsShow={setIsShow}
                isShow={isShow}
              />
            </>
          )
        }
      />
      <ReactTooltip id="my-tooltip" />
    </>
  );
}
