import { FETCH_BANK_SUCCESS } from "./BankActionTypes";

const initialState = { paymentDetails: [] };

export const BankReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANK_SUCCESS:
      return { ...state, paymentDetails: action.data || action };

    default:
      return state;
  }
};
