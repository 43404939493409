import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Head from "../components/NavBar/Head";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap//Modal";
import { BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";
import DataTables from "../components/DataTables";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CASHBOOK_ENTRIES_SUCCESS } from "../store/Cashbook/CashbookActionTypes";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Modals from "../components/Modal";

const KhataBook = () => {
  const { entries, otherInfo } = useSelector((state) => state.cashbookEntries);

  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });

  const [selectedItems, setSelectedItems] = useState([]);
  const [modalActions, setModalActions] = useState({
    showModal: false,
    title: "In Entry",
    submitButtonTitle: "Add Cash",
  });
  const [deleteCashbookEntriesAction, setDeleteCashbookEntriesAction] =
    useState({
      showConfirmationModal: false,
    });

  const dispatch = useDispatch();

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,
      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "summary",
      title: "Title",
    },
    {
      data: "amount",
      title: "Amount",
    },
    {
      data: "transaction_type",
      title: "Transaction Type",
      class: "text-center",
      render: function (data, type, row) {
        if (row.transaction_type === 1) {
          return `<div className="badge text-success" style="background: rgba(26, 135, 84, 0.2);">Cash In</div>`;
        }
        return `<div className="badge text-danger" style="background: rgba(255, 124, 1, 0.2);">Cash Out</div>`;
      },
    },
    // {
    //   data: "payment_mode",
    //   title: "Payment Mode",
    // },
    {
      data: "date",
      title: "Billing Date",
    },

    {
      data: null,
      class: "text-left align-middle adjust-custom-padding",
      title: "Actions",
      width: "170px",
      orderable: false,
      render: function (data, type, row) {
        return renderToStaticMarkup(
          <div className="text-right">
            <Button
              variant="light"
              size="sm"
              data-id={row.id}
              data-action="viewCashbookEntry"
              className="btn-icon mx-1"
            >
              <BsEye size={17} />
            </Button>
            <Button
              variant="light"
              size="sm"
              className="mx-1 btn-icon"
              data-id={row.id}
              data-action="updateCashbookEntry"
            >
              <BsPen size={17} />
            </Button>
            <Button
              variant="light"
              size="sm"
              className="btn-icon text-danger mx-1"
              data-id={row.id}
              data-action="confirmBeforeDeletion"
            >
              <BsTrash3 size={17} />
            </Button>
          </div>
        );
      },
    },
  ];

  const cashbookValidationSchema = Yup.object().shape({
    title: Yup.string().required().label("Title"),
    summary: Yup.string().required().label("Summary"),
    amount: Yup.string().required().label("Amount"),
    transaction_type: Yup.string().required().label("Transaction Type"),
    payment_mode: Yup.string().required().label("Payment Mode").nullable(),
    date: Yup.string().required().label("Date"),
  });

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(cashbookValidationSchema),
  });
  // const createOrUpdateCashbookEntry = async (data) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADD_CASHBOOK}`,
  //       null, // Pass null as the second parameter when using params
  //       {
  //         params: data, // Include your data as params
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
  //     reset();
  //     setModalActions({
  //       ...modalActions,
  //       showModal: false,
  //       title: "",
  //     });

  //     return response;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  const createOrUpdateCashbookEntry = async (data) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${
          process.env.REACT_APP_ADD_CASHBOOK
        }${data.id ? `/${data.id}` : ""}`,
        data
      )
      .then((response) => {
        if (response?.status === 200) {
          if (data[0]?.id) {
            toast.success("You have successfully updated khataBook entry", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("You have successfully created khataBook entry", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });

          reset();
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
    setModalActions({
      ...modalActions,
      showModal: false,
      title: "",
    });
  };

  const confirmBeforeDeletion = (id, requestType = 0) => {
    setDeleteCashbookEntriesAction({
      ...deleteCashbookEntriesAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };

  const viewCashbookEntry = (id) => {
    const getEntry = entries.filter((item) => item.id === parseInt(id))[0];
    Object.keys(getEntry).map((item) => setValue(`${item}`, getEntry[item]));
    setModalActions({
      ...modalActions,
      showModal: true,
      title: `View Cashbook ${
        getValues("transaction_type") === 1 ? `Cash In` : `Cash Out`
      } Entry`,
      submitButtonTitle: "",
    });
  };

  const updateCashbookEntry = (id) => {
    const getEntry = entries.filter((item) => item.id === parseInt(id))[0];
    Object.keys(getEntry).map((item) => setValue(`${item}`, getEntry[item]));
    setModalActions({
      ...modalActions,
      showModal: true,
      title: `Update Cashbook ${
        getValues("transaction_type") === 1 ? `Cash In` : `Cash Out`
      } Entry`,
      submitButtonTitle: "Update",
    });
  };

  const deleteCashbookEntries = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_CASHBOOK_ENTRIES}`,
        {
          id: deleteCashbookEntriesAction.idsToDelete,
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          setDeleteCashbookEntriesAction({
            ...deleteCashbookEntriesAction,
            showConfirmationModal: false,
            idsToDelete: [],
          });
          toast.success(
            checkedCheckBoxes.length > 1
              ? "Entries deleted successfully"
              : "Entry deleted successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        setValue("bill_image", base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Head title="Khatabook">
        <Button
          size="sm"
          variant="danger"
          className="me-2"
          style={{ display: "none" }}
          id="multipleDeleteBtn"
          onClick={() => confirmBeforeDeletion("", 1)}
        >
          Delete Entries
        </Button>
        <Button
          size="sm"
          variant="success"
          className="px-4 me-2"
          onClick={() => {
            setValue("date", moment().format("YYYY-MM-DD"));
            setValue("transaction_type", "1");
            setModalActions({
              ...modalActions,
              showModal: true,
              title: "In Entry",
              submitButtonTitle: "Add Cash",
            });
          }}
        >
          In Cash
        </Button>
        <Button
          size="sm"
          variant="danger"
          className="px-3"
          onClick={() => {
            setValue("date", moment().format("YYYY-MM-DD"));
            setValue("transaction_type", "0");
            setModalActions({
              ...modalActions,
              showModal: true,
              title: "Out Entry",
              submitButtonTitle: "Withdraw Cash",
            });
          }}
        >
          Out Cash
        </Button>
      </Head>
      <Row className="m-0 g-2">
        <Col>
          <Card
            className="border-0"
            style={{ background: "rgba(26, 135, 84, .2)" }}
          >
            <Card.Body>
              <h2 className="m-0">{otherInfo?.totalAmountIn || 0}</h2>
              <p className="text-success m-0" style={{ fontSize: 15 }}>
                Total In
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            className="border-0"
            style={{ background: "rgba(255, 124, 1, .2)" }}
          >
            <Card.Body>
              <h2 className="m-0">{otherInfo?.totalAmountOut || 0}</h2>
              <p className="text-danger m-0" style={{ fontSize: 15 }}>
                Total Out
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            className="border-0"
            style={{ background: "rgba(13, 110, 253, .2)" }}
          >
            <Card.Body>
              <h2 className="m-0">
                {otherInfo?.totalAmountIn - otherInfo?.totalAmountOut || 0}
              </h2>
              <p className="text-gray m-0" style={{ fontSize: 15 }}>
                Net Balance
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} className="mt-5">
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <DataTables
                url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_CASHBOOK_ENTRIES}`}
                columns={columns}
                defaultSortedColumnIndex={2}
                confirmBeforeDeletion={confirmBeforeDeletion}
                setSelectedItems={setSelectedItems}
                dispatchCallType={FETCH_CASHBOOK_ENTRIES_SUCCESS}
                viewCashbookEntry={viewCashbookEntry}
                updateCashbookEntry={updateCashbookEntry}
              />
            </Card.Body>
            <ToastContainer style={{ top: "60px" }} />{" "}
          </Card>
        </Col>
      </Row>

      <Modal show={modalActions.showModal}>
        <Modal.Header className="border-0 m-auto">
          <h6 className="m-0">{modalActions.title}</h6>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label htmlFor="title" className="mb-0">
              Title
            </Form.Label>

            <InputGroup className="mb-3">
              <Form.Control
                id="title"
                {...register("title")}
                isInvalid={!!errors.title?.message}
              />
              <Form.Control.Feedback
                type={`${errors.title?.message && "invalid"}`}
              >
                {errors.title?.message}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Label htmlFor="amount" className="mb-0">
              Amount
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                id="amount"
                {...register("amount")}
                isInvalid={!!errors.amount?.message}
              />
              <Form.Control.Feedback
                type={`${errors.amount?.message && "invalid"}`}
              >
                {errors.amount?.message}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Label htmlFor="summary" className="mb-0">
              Description
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                as="textarea"
                id="summary"
                {...register("summary")}
                isInvalid={!!errors.summary?.message}
              />
              <Form.Control.Feedback
                type={`${errors.summary?.message && "invalid"}`}
              >
                {errors.summary?.message}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Label htmlFor="basic-url">Payment Mode</Form.Label>
            <InputGroup className="mb-3">
              <Form.Check
                type="radio"
                id={`cash`}
                value="Cash"
                {...register("payment_mode")}
                defaultChecked={getValues("payment_mode") === "Cash"}
                isInvalid={!!errors.payment_mode?.message}
                feedback={errors.payment_mode?.message}
                feedbackType="invalid"
              />
              <Form.Label htmlFor="cash" className="mb-0 mt-1 ps-2">
                Cash
              </Form.Label>
              <Form.Check
                className="ms-4"
                type="radio"
                id={`online`}
                value="Online"
                {...register("payment_mode")}
                defaultChecked={getValues("payment_mode") === "Online"}
                isInvalid={!!errors.payment_mode?.message}
              />
              <Form.Label htmlFor="online" className="mb-0 mt-1 ps-2">
                Bank
              </Form.Label>
            </InputGroup>

            <Form.Label htmlFor="date" className="mb-0">
              Date
            </Form.Label>
            <InputGroup className="mb-3">
              <DatePicker
                required
                name="date"
                id="date"
                format="DD/MM/YYYY"
                selected={
                  watch("date") !== undefined
                    ? new Date(watch("date"))
                    : new Date()
                }
                onChange={(e) =>
                  setValue("date", moment(e).format("YYYY-MM-DD"))
                }
                isInvalid={!!errors.date?.message}
                customInput={<Form.Control aria-describedby="basic-addon1" />}
              />
              {/* <Form.Control
                id="date"
                {...register("date")}
                isInvalid={!!errors.date?.message}
              /> */}
              <Form.Control.Feedback
                type={`${errors.date?.message && "invalid"}`}
              >
                {errors.date?.message}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Label htmlFor="bill_image" className="mb-0">
              Attach Bill
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control type="file" onChange={handleFileChange} />
              {/* <Form.Control
                as="textarea"
                id="bill_image"
                {...register("bill_image")}
              /> */}
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              reset();
              setModalActions({
                ...modalActions,
                showModal: false,
                title: "",
              });
            }}
          >
            Cancel
          </Button>
          {modalActions.submitButtonTitle && (
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmit(createOrUpdateCashbookEntry)}
              disabled={isSubmitting}
            >
              {modalActions.submitButtonTitle}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={deleteCashbookEntriesAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you sure you want to delete selected entry?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setDeleteCashbookEntriesAction({
                ...deleteCashbookEntriesAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => deleteCashbookEntries()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </>
  );
};

export default KhataBook;
