import { Button, Modal } from "react-bootstrap";

export default function Modals({ showModal, handleClose }) {
  return (
    <Modal show={showModal?.is} onHide={handleClose} centered>
      <Modal.Header className="d-flex justify-content-center border-0">
        <Modal.Title style={{ color: "black" }}>Warning!</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: "black",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {showModal?.msg}{" "}
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          variant="primary"
          className="closeButton"
          onClick={() => handleClose()}
        >
          Close 
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
