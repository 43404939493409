import { FETCH_INVOICE_SUCCESS } from "./InvoiceActionTypes";

const initialState = { invoices: [] };

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICE_SUCCESS:
      return { ...state, invoices: [...action.data],otherInfo: action.otherInfo||{} };

    default:
      return state;
  }
};
