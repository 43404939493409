import "../App.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Modals from "../components/Modal";
import Modal from "react-bootstrap/Modal";
import { Button, Badge, Col, Form, Row, CardBody } from "react-bootstrap";
import { BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import ReactDOMServer from "react-dom/server";
import DataTables from "../components/DataTables";
import Head from "../components/NavBar/Head";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import { InputGroup } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FETCH_USERS_SUCCESS } from "../store/Users/UsersActionTypes";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Users = () => {
  const dispatch = useDispatch();
  const [modalActions, setModalActions] = useState({
    showModal: false,
    modalTitle: "Create New User",
    submitButtonTitle: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [rolePermission, setRolepermission] = useState([]);
  const [deleteUserAction, setDeleteUserAction] = useState({
    showConfirmationModal: false,
  });
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [selectedItems, setSelectedItems] = useState([]);
  const { userInfo } = useSelector((state) => state.userInfo);
  const checkRole = userInfo?.user_data?.role;
  const { users } = useSelector((state) => state?.users);
  const [capitalizedFields, setCapitalizedFields] = useState({
    firstname: true,
    lastname: true,
  });
  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
  const [isDeleting, setIsDeleting] = useState(false);
  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,
      visible:
        checkRole?.users?.includes("delete") ||
        checkRole?.users?.includes("Delete"),
      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "firstname",
      class: "text-left align-middle",
      title: "Name",
    },
    {
      data: "email",
      class: "text-left align-middle",
      title: "Email",
    },
    {
      data: "role_name",
      class: "text-left align-middle",
      title: "Role",
    },
    {
      data: "email_verified_at",
      class: "text-left align-middle",
      title: "Email Verification Status",
      render: function (data) {
        let content = <Badge bg="success">Verified</Badge>;
        if (!data?.email_verified_at) {
          content = <Badge bg="danger">Unverified</Badge>;
        }
        return ReactDOMServer.renderToString(
          <div className="text-right">{content}</div>
        );
      },
    },
    {
      data: null,
      class: "text-left align-middle ",

      title: "Actions",
      width: "150px",
      orderable: false,
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          <div className="text-right">
            {(checkRole?.users?.includes("view") ||
              checkRole?.users?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="View"
                variant="light"
                size="sm"
                className="btn-icon mx-1"
                data-id={JSON.stringify(row)}
                data-action="viewUserInfo"
              >
                <BsEye size={17} />
              </Button>
            )}
            {(checkRole?.users?.includes("edit") ||
              checkRole?.users?.includes("Edit")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit"
                variant="light"
                size="sm"
                className=" btn-icon mx-1"
                data-id={JSON.stringify(row)}
                data-action="updateUserInfo"
              >
                <BsPen size={17} />
              </Button>
            )}
            {(checkRole?.users?.includes("delete") ||
              checkRole?.users?.includes("Delete")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Delete"
                variant="light"
                size="sm"
                className="btn-icon text-danger mx-1"
                data-id={row.id}
                data-action="confirmBeforeDeletion"
              >
                <BsTrash3 size={17} />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const getRolePermission = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_ROLES}`,
        {
          headers: {
          Authorization: `Bearer ${userInfo?.token}`, 
          Accept: "application/json",
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setRolepermission(res?.data?.data);
        }
        return res;
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getRolePermission();
  }, []);

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const userValidationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    role_id: Yup.string().required("Role is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: modalActions.modalTitle.includes("Create")
      ? Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("Password is required")
      : Yup.string(),
    confirmPassword: modalActions.modalTitle.includes("Create")
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required")
      : Yup.string(),
  });
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userValidationSchema),
  });

  const viewUserInfo = (data, isEditable = false) => {
    reset();
    const getEntry = users?.filter(
      (row) => row.id === parseInt(JSON.parse(data)?.id, 10)
    );
    const { id, firstname, lastname, email, role_id } = getEntry[0];
    setValue("user_id", id);
    setValue("firstname", firstname);
    setValue("lastname", lastname);
    setValue("email", email);
    setValue("role_id", role_id);

    setModalActions({
      ...modalActions,
      showModal: true,
      modalTitle: !isEditable ? `View User Info` : `Edit User Info`,
      submitButtonTitle: isEditable ? "Update" : "",
    });
  };
  const updateUserInfo = (data) => {
    reset();
    viewUserInfo(data, true);
  };

  const createOrUpdateUser = async (data) => {
    setIsDeleting(true);
    const url = modalActions.modalTitle.includes("Create")
      ? `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_REGISTRATION_URL}`
      : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE}`;
    await axios
      .post(url, data,{
        headers: {
        Authorization: `Bearer ${userInfo?.token}`, 
        Accept: "application/json",
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          toast.success(
            modalActions.modalTitle.includes("Create")
              ? "User created successfully"
              : "User updated successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          reset();
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          setRefreshData(!refreshData);
        } else {
          setShowPopup({
            ...showPopup,
            is: true,
            msg: response?.data?.message,
          });
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
    setModalActions({
      ...modalActions,
      showModal: false,
      title: "",
    });
    setTimeout(() => setIsDeleting(false), 2000);
  };
  useEffect(() => {
    if (modalActions.showModal) {
      setCapitalizedFields({
        firstname: true,
        lastname: true,
      });
    }
  }, [modalActions.showModal]);

  const confirmBeforeDeletion = (id, requestType = false) => {
    setDeleteUserAction({
      ...deleteUserAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };

  const deleteUser = async () => {
    setIsDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_USER}`,
        {
          user_id: deleteUserAction.idsToDelete,
        },
        {
          headers: {
          Authorization: `Bearer ${userInfo?.token}`, 
          Accept: "application/json",
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            checkedCheckBoxes.length > 1
              ? "Users deleted successfully"
              : "User deleted successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          checkAll.checked = false;
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          setRefreshData(!refreshData);
          setDeleteUserAction({
            ...deleteUserAction,
            showConfirmationModal: false,
            idsToDelete: [],
          });
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
    setTimeout(() => setIsDeleting(false), 2000);
  };
  const handleNameChange = (fieldName, e) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      capitalizedFields[fieldName] && inputValue !== ""
        ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        : inputValue;

    setValue(fieldName, capitalizedValue);
    setCapitalizedFields({
      ...capitalizedFields,
      [fieldName]: false,
    });
  };

  return (
    <>
      <Head title="Users">
        {(checkRole?.users?.includes("delete") ||
          checkRole?.users?.includes("Delete")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">
                {checkedCheckBoxes.length > 1
                  ? "Click to delete Users"
                  : "Click to delete User"}
              </Tooltip>
            }
          >
            <Button
              className="btn btn-danger btn-sm me-1"
              style={
                checkedCheckBoxes.length
                  ? { display: "block" }
                  : { display: "none" }
              }
              id="multipleDeleteBtn"
              onClick={() => confirmBeforeDeletion("", 1)}
            >
              {checkedCheckBoxes.length > 1 ? "Delete Users" : "Delete User"}
            </Button>
          </OverlayTrigger>
        )}
        {(checkRole?.users?.includes("create") ||
          checkRole?.users?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">Click to add user</Tooltip>}
          >
            <Button
              onClick={() => {
                reset();
                setModalActions({
                  ...modalActions,
                  showModal: true,
                  modalTitle: `Create New User`,
                  submitButtonTitle: "Create",
                });
              }}
              className="btn btn-sm btn-neutral button"
            >
              Add User
            </Button>
          </OverlayTrigger>
        )}
      </Head>
      <Card>
        {/* <CardHeader className="row d-flex justify-content-end  g-0">
          <Col>Filters</Col>
          <Col xs="auto">
            <Form.Select
              size="sm"
              style={{ width: "40px" }}
              aria-label="Default select example"
              onChange={(e) =>
                setAdditionalFilter({
                  ...additionalFilter,
                  roles: e.target.value,
                })
              }
            >
              <option>Roles</option>
              {[...new Set(roles.map((role) => role.role))].map(
                (uniqueRole, index) => (
                  <option key={index} value={uniqueRole}>
                    {uniqueRole}
                  </option>
                )
              )}
            </Form.Select>
          </Col>
        </CardHeader> */}
        <CardBody>
          <DataTables
            url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_USER_INFO}`}
            columns={columns}
            defaultSortedColumnIndex={3}
            viewUserInfo={viewUserInfo}
            updateUserInfo={updateUserInfo}
            dispatchCallType={FETCH_USERS_SUCCESS}
            confirmBeforeDeletion={confirmBeforeDeletion}
            setSelectedItems={setSelectedItems}
            refreshData={refreshData}
          />
        </CardBody>
      </Card>
      <ToastContainer style={{ top: "60px" }} />
      <Modal show={modalActions.showModal} size="md">
        <Modal.Header className="border-0 text-center justify-content-center">
          <Modal.Title as="h6">{modalActions.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-role-modal">
          <Row className="gx-2">
            <Col xs={6}>
              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  // {...register("firstname")}
                  value={watch("firstname")}
                  onChange={(e) => handleNameChange("firstname", e)}
                  // onChange={(e) => {
                  //   setValue("firstname", e.target.value);
                  // }}
                />
                {errors.firstname &&
                  errors.firstname?.message !== undefined && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.firstname?.message}
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  {...register("lastname")}
                  onChange={(e) => handleNameChange("lastname", e)}
                />
                {errors.lastname && errors.lastname?.message !== undefined && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.lastname?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>Role</Form.Label>

                <Form.Select
                  {...register("role_id")}
                  isInvalid={!!errors.role_id}
                  placeholder="Select Role"
                >
                  <option value="">Select Role</option>
                  {rolePermission.map((row, index) => (
                    <option value={row.id} key={index}>
                      {row?.role}
                    </option>
                  ))}
                </Form.Select>
                {errors.role_id && errors.role_id?.message !== undefined && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.role_id?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email Address"
                  {...register("email")}
                />
                {errors.email && errors.email?.message !== undefined && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            {modalActions.modalTitle.includes("Create") && (
              <>
                <Col xs={12}>
                  <Form.Group className="mb-2" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup
                      className="mb-3 rtrtrr"
                      style={{ border: "none", outline: "none" }}
                    >
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter Password"
                        {...register("password")}
                      />
                      <InputGroup.Text
                        className="login-hide"
                        onClick={() =>
                          passwordShown
                            ? setPasswordShown(false)
                            : setPasswordShown(true)
                        }
                      >
                        {passwordShown ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </InputGroup.Text>
                    </InputGroup>

                    {errors.password &&
                      errors.password?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.password?.message}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-2" controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup
                      className="mb-3 rtrtrr"
                      style={{ border: "none", outline: "none" }}
                    >
                      <Form.Control
                        type={cpasswordShown ? "text" : "password"}
                        placeholder="Enter Confirm Password"
                        {...register("confirmPassword")}
                      />
                      <InputGroup.Text
                        className="login-hide"
                        onClick={() =>
                          cpasswordShown
                            ? setCPasswordShown(false)
                            : setCPasswordShown(true)
                        }
                      >
                        {cpasswordShown ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </InputGroup.Text>
                    </InputGroup>

                    {errors.confirmPassword &&
                      errors.confirmPassword?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.confirmPassword?.message}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>

          <div className="d-flex justify-content-end mt-4">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Click to close</Tooltip>}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  setModalActions({
                    ...modalActions,
                    showModal: false,
                    title: ``,
                    submitButtonTitle: "",
                  });
                  reset();
                }}
                size="sm"
              >
                Close
              </Button>
            </OverlayTrigger>
            {modalActions.submitButtonTitle ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">Click to create user</Tooltip>
                }
              >
                <Button
                  variant="primary"
                  size="sm"
                  className="ms-2"
                  disabled={isDeleting}
                  onClick={handleSubmit(createOrUpdateUser)}
                >
                  {isDeleting ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ height: "21px", width: "21px" }}
                      ></div>
                    </div>
                  ) : (
                    modalActions.submitButtonTitle
                  )}
                </Button>
              </OverlayTrigger>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteUserAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you sure you wants to delete the selected{" "}
          {checkedCheckBoxes.length > 1 ? "users" : "user"} ?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() =>
              setDeleteUserAction({
                ...deleteUserAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
            size="sm"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={isDeleting}
            onClick={() => deleteUser()}
          >
            {isDeleting ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ height: "21px", width: "21px" }}
                ></div>
              </div>
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
      <ReactTooltip id="my-tooltip" />
    </>
  );
};

export default Users;
