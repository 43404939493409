import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  FloatingLabel,
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import { RiPencilFill } from "react-icons/ri";
import generatePDF from "react-to-pdf";
import "./Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function Templates3(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const { paymentDetails } = useSelector((state) => state.paymentDetails);
  const formatDate = userInfo?.account_preferences?.date_formate;
  const filterPaymentDetails = paymentDetails?.filter((row) => row?.isPrimary);
  const {
    requestType,
    isCreateInvoice,
    applyGST,
    applyDIS,
    additionallDiscount,
    toWords,
    addFields,
    removeFields,
    setApplyGST,
    setApplyDIS,
    setAdditionalDiscount,
    disableSubmit,
    formik,
    showTemplate,
    handlePaidToChange,
    paymentDetailsEditable,
    setPaymentDetailsEditable,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    if (parseFloat(values.special_discount) > 0) {
      setAdditionalDiscount(true);
    }
    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/invoices");
    }
  }, [state?.download, isDataLoaded, navigate]);

  // function stateDownload() {
  //   if (state?.download === "true") {
  //     document.getElementById("pdf-button").click();
  //     navigate("/invoices");
  //   }
  // }

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  function capitalizeWords(sentence) {
    // Split the sentence into words
    const words = sentence.split(" ");
    // Capitalize the first letter of each word
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    // Join the capitalized words back into a sentence
    return capitalizedWords.join(" ");
  }

  return (
    <div className="main222">
      <Card style={{ width: "800px" }}>
        <Form noValidate onSubmit={handleSubmit}>
          <div ref={componentRef} style={{ paddingTop: "20px" }}>
            <div ref={ref}>
              <div className="m-4 pb-2 " style={{ border: "2px solid black" }}>
                <Row className="mt-2">
                  <Col sm={6} className="pt-3">
                    {" "}
                    <div>
                      {userInfo?.account_preferences?.attached_logo ? (
                        <img
                          alt="temp"
                          src={`
                              ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                            `}
                          style={{
                            height: "60px",
                          }}
                        />
                      ) : (
                        "{Logo}"
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Text className="text111 text-start d-inline">
                      Address:{" "}
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.address ||
                      userInfo?.account_preferences?.city ||
                      userInfo?.account_preferences?.pincode
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city} - ${userInfo?.account_preferences?.pincode}`
                        : "{Company name, city- Pincode}"}
                    </Form.Text>
                    <br />
                    <Form.Text className="text111 text-start d-inline">
                      Email:{" "}
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.email_address
                        ? userInfo?.account_preferences?.email_address
                        : "{Email address}"}
                    </Form.Text>
                    <br />
                    <Form.Text className="text111 text-start d-inline">
                      Contact:{" "}
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                        : ""}
                    </Form.Text>
                  </Col>
                </Row>
                <div
                  style={{
                    backgroundColor: "#d5d2dc",
                    height: "1px",
                    marginTop: "15px",
                  }}
                ></div>
                <h6 className="my-2 text-center ">Payment Invoice</h6>
                <div
                  style={{
                    backgroundColor: "#d5d2dc",
                    height: "1px",
                    marginTop: "10px",
                  }}
                ></div>

                <div className="mx-2">
                  <div className="row">
                    {!isCreateInvoice && (
                      <div className="col-md-6 col-sm-6 ps-0 pe-2 mt-2 ">
                        <InputGroup>
                          <InputGroup.Text
                            style={{ width: "135px" }}
                            id="basic-addon1"
                            className="text33  text-start"
                          >
                            Invoice
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Enter Invoice No."
                            required
                            value={values?.invoice_no}
                            readOnly
                          />
                        </InputGroup>
                      </div>
                    )}
                    <div
                      className={
                        !isCreateInvoice
                          ? "col-md-6 col-sm-6 ps-0 pe-0 mt-2 "
                          : "col-md-12 col-sm-12 ps-0 pe-0 mt-2 "
                      }
                    >
                      <>
                        <DatePicker
                          className="w-100"
                          required
                          disabled={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                          name="date"
                          id="date"
                          format={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            values?.date ? new Date(values?.date) : null
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "date",
                              e ? moment(e).format("YYYY-MM-DD") : ""
                            );
                          }}
                          maxDate={new Date()}
                          {...props}
                          customInput={
                            <InputGroup>
                              <InputGroup.Text
                                style={{ width: "135px" }}
                                id="basic-addon1"
                                className=" text33 text-start"
                              >
                                Date
                              </InputGroup.Text>

                              <Form.Control
                                aria-describedby="basic-addon1"
                                value={moment(values?.date).format(
                                  formatDate === null ||
                                    formatDate === undefined
                                    ? "DD MMM, YYYY"
                                    : formatDate
                                )}
                              />
                            </InputGroup>
                          }
                        />

                        {touched.date && errors.date && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mb-1"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        )}
                      </>
                    </div>
                  </div>
                  <InputGroup className="mt-2 d-flex align-items-center">
                    <InputGroup.Text
                      id="basic-addon3"
                      className="text33"
                      style={{ whiteSpace: "nowrap", width: "135px" }}
                    >
                      Project Name
                    </InputGroup.Text>
                    <div className="flex-grow-1">
                      <AutoComplete
                        // wrapperClassName={
                        //   isCreateInvoice
                        //     ? "wrapper-custom-width"
                        //     : "wrapper-custom-width-2"
                        // }
                        callbackFieldValue={(e) =>
                          setFieldValue("project_name", e.target.value)
                        }
                        requestType="bills"
                        searchingFor="project_name"
                        fieldValue={values.project_name}
                        className="rounded-start-0 w-100"
                        name="project_name"
                        id="project_name"
                        aria-describedby="basic-addon3"
                        placeholder="Enter Project Name"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                        token={userInfo?.token}
                      />
                    </div>
                  </InputGroup>

                  <InputGroup className="mt-2">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ width: "135px" }}
                      className="text33 text-start"
                    >
                      Bill To
                    </InputGroup.Text>
                    <div className="flex-grow-1">
                      <AutoComplete
                        // wrapperClassName={
                        //   isCreateInvoice
                        //     ? "wrapper-custom-width"
                        //     : "wrapper-custom-width-2"
                        // }
                        requestType="bills"
                        searchingFor="paid_to"
                        callbackFieldValue={handlePaidToChange}
                        fieldValue={values.paid_to}
                        className="w-100 rounded-start-0"
                        name="paid_to"
                        id="paid_to"
                        aria-describedby="basic-addon1"
                        placeholder="Bill To"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                        token={userInfo?.token}
                      />
                    </div>
                  </InputGroup>

                  {touched.paid_to && errors.paid_to && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1"
                    >
                      {errors.paid_to}
                    </Form.Control.Feedback>
                  )}
                  <InputGroup className="mt-2">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ width: "135px" }}
                      className="text33 text-start"
                    >
                      Address
                    </InputGroup.Text>
                    <div className="flex-grow-1">
                      <AutoComplete
                        // wrapperClassName={
                        //   isCreateInvoice
                        //     ? "wrapper-custom-width"
                        //     : "wrapper-custom-width-2"
                        // }
                        requestType="bills"
                        searchingFor="address"
                        callbackFieldValue={(e) =>
                          setFieldValue("address", e.target.value)
                        }
                        fieldValue={values.address}
                        as="textarea"
                        rows={1}
                        className="w-100 rounded-start-0"
                        name="address"
                        id="address"
                        aria-describedby="basic-addon1"
                        placeholder="Address"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                        token={userInfo?.token}
                      />
                    </div>
                  </InputGroup>

                  {touched.address && errors.address && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1"
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  )}

                  <Table striped hover className="mt-2">
                    <thead>
                      <tr style={{ borderWidth: "1px" }}>
                        <th
                          className="text9 text-start"
                          style={{ width: "135px", verticalAlign: "middle" }}
                        >
                          Date
                        </th>
                        <th
                          className="text9"
                          style={{
                            borderWidth: "1px",
                            verticalAlign: "middle",
                          }}
                        >
                          Details
                        </th>
                        {/* <th
                          className="text9"
                          style={{
                            borderWidth: "1px",
                            width: "185px",
                          }}
                        >
                          Payments
                        </th> */}
                        <th
                          className="text9"
                          style={{ width: "115px", verticalAlign: "middle" }}
                        >
                          Item Price
                        </th>
                        {/* <th
                          className="text9"
                          style={{ width: "100px", borderWidth: "1px" }}
                        >
                          Quantity
                        </th> */}
                        <th
                          className="text9"
                          style={{
                            width: "100px",
                            borderWidth: "1px",
                            verticalAlign: "middle",
                          }}
                        >
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={values.item_unit?.toUpperCase()}
                            variant="secondary bg-white border-0 p-0 shadow-none text9 align-middle applyClassminWidth-auto"
                            size="sm"
                            onSelect={(e) => setFieldValue("item_unit", e)}
                          >
                            <Dropdown.Item eventKey="Quantity">
                              QUANTITY
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Hours">
                              HOURS
                            </Dropdown.Item>
                          </DropdownButton>
                        </th>
                        <th
                          className="text9"
                          style={{
                            borderWidth: "1px",
                            width: "120px",
                            verticalAlign: "middle",
                          }}
                        >
                          Payments
                        </th>

                        {(requestType === "edit" ||
                          (isCreateInvoice &&
                            values?.voucher_descriptions.length > 1)) && (
                          <th className="tab2th"></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {values?.voucher_descriptions?.map((row, index) => {
                          total +=
                            row.quantity || row.item_price
                              ? parseFloat(row.quantity * row.item_price)
                              : 0;
                          subTotal = total;
                          Quantity += row.quantity
                            ? parseFloat(row.quantity)
                            : 0;

                          applieddiscount =
                            (subTotal * (values?.has_discounts || 0)) / 100;
                          netTotal = (
                            parseFloat(total) -
                            parseFloat(applyDIS ? applieddiscount : 0)
                          ).toFixed(2);
                          appliedgst = applyDIS
                            ? (netTotal * (values?.has_gst || 0)) / 100
                            : (subTotal * (values?.has_gst || 0)) / 100;

                          discountVal = applyDIS
                            ? (
                                parseFloat(netTotal) -
                                (additionallDiscount
                                  ? values.special_discount
                                  : 0) +
                                parseFloat(applyGST ? appliedgst : 0)
                              ).toFixed(2)
                            : (
                                parseFloat(subTotal) -
                                (additionallDiscount
                                  ? values.special_discount
                                  : 0) +
                                parseFloat(applyGST ? appliedgst : 0)
                              ).toFixed(2);
                          return (
                            <>
                              <tr
                                key={index}
                                style={{ height: "10px", borderWidth: "1px" }}
                              >
                                <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <DatePicker
                                      wrapperClassName="datewrapper"
                                      customInput={
                                        <Form.Control
                                          aria-describedby="basic-addon1"
                                          className="w-100"
                                        />
                                      }
                                      required
                                      maxDate={new Date()}
                                      name="item_date"
                                      id="date"
                                      // change
                                      dateFormat={
                                        formatDate === null ||
                                        formatDate === undefined
                                          ? "dd MMM, yyyy"
                                          : formatDate
                                              ?.replace(/D/g, "d")
                                              ?.replace(/Y/g, "y")
                                      }
                                      selected={
                                        values.voucher_descriptions[index]
                                          ?.item_date
                                          ? new Date(
                                              values.voucher_descriptions[
                                                index
                                              ].item_date
                                            )
                                          : null
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          `voucher_descriptions.${index}.item_date`,
                                          date
                                            ? moment(date).format("YYYY-MM-DD")
                                            : ""
                                        );
                                      }}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11 text-start"
                                      style={{ width: "100%" }}
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_date`
                                      )}
                                    >
                                      {moment(
                                        getFieldProps(
                                          `voucher_descriptions.${index}.item_date`
                                        ).value
                                      ).format(
                                        `${
                                          formatDate === null ||
                                          formatDate === undefined
                                            ? "DD MMM, YYYY"
                                            : formatDate
                                        }`
                                      )}
                                    </Form.Text>
                                  )}
                                  {touched.voucher_descriptions &&
                                    touched.voucher_descriptions[index]
                                      ?.item_date &&
                                    errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_date && (
                                      <Form.Control.Feedback
                                        className="d-block"
                                        type="invalid"
                                      >
                                        {errors.voucher_descriptions &&
                                          errors.voucher_descriptions[index]
                                            ?.item_date}
                                      </Form.Control.Feedback>
                                    )}
                                </td>
                                <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <AutoComplete
                                      requestType="billdescriptions"
                                      searchingFor="item_description"
                                      name={`voucher_descriptions.${index}.item_description`}
                                      callbackFieldValue={(e) =>
                                        setFieldValue(
                                          `voucher_descriptions.${index}.item_description`,
                                          e.target.value
                                        )
                                      }
                                      fieldValue={row.item_description}
                                      as="textarea"
                                      rows={1}
                                      required
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      token={userInfo?.token}
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11  text-start"
                                      style={{ width: "100%" }}
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_description`
                                      )}
                                    >
                                      {
                                        getFieldProps(
                                          `voucher_descriptions.${index}.item_description`
                                        ).value
                                      }
                                    </Form.Text>
                                  )}
                                  {touched.voucher_descriptions &&
                                    touched.voucher_descriptions[index]
                                      ?.item_description &&
                                    errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_description && (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors.voucher_descriptions &&
                                          errors.voucher_descriptions[index]
                                            ?.item_description}
                                      </Form.Control.Feedback>
                                    )}
                                </td>

                                {/* <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      maxLength={7}
                                      required
                                      rows={1}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onKeyPress={numberonly}
                                      name="item_price"
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_price`
                                      )}
                                      type="text"
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11  text-start"
                                      style={{ width: "100%" }}
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_price`
                                      )}
                                    >
                                      {
                                        getFieldProps(
                                          `voucher_descriptions.${index}.item_price`
                                        ).value
                                      }
                                    </Form.Text>
                                  )}
                                  {touched.voucher_descriptions &&
                                    touched.voucher_descriptions[index]
                                      ?.item_price &&
                                    errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_price && (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors.voucher_descriptions &&
                                          errors.voucher_descriptions[index]
                                            ?.item_price}
                                      </Form.Control.Feedback>
                                    )}
                                </td> */}

                                <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      maxLength={7}
                                      required
                                      rows={1}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onKeyPress={numberonly}
                                      name="item_price"
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_price`
                                      )}
                                      type="text"
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11  text-start"
                                      style={{ width: "100%" }}
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.item_price`
                                      )}
                                    >
                                      {
                                        getFieldProps(
                                          `voucher_descriptions.${index}.item_price`
                                        ).value
                                      }
                                    </Form.Text>
                                  )}
                                  {touched.voucher_descriptions &&
                                    touched.voucher_descriptions[index]
                                      ?.item_price &&
                                    errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_price && (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        style={{
                                          display: "block",
                                        }}
                                      >
                                        {errors.voucher_descriptions &&
                                          errors.voucher_descriptions[index]
                                            ?.item_price}
                                      </Form.Control.Feedback>
                                    )}
                                </td>
                                <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      maxLength={4}
                                      required
                                      type="text"
                                      rows={1}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onKeyPress={numberonly1}
                                      name="quantity"
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.quantity`
                                      )}
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11  text-start"
                                      style={{ width: "100%" }}
                                      {...getFieldProps(
                                        `voucher_descriptions.${index}.quantity`
                                      )}
                                    >
                                      {
                                        getFieldProps(
                                          `voucher_descriptions.${index}.quantity`
                                        ).value
                                      }
                                    </Form.Text>
                                  )}
                                  {touched.voucher_descriptions &&
                                    touched.voucher_descriptions[index]
                                      ?.quantity &&
                                    errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.quantity && (
                                      <Form.Control.Feedback
                                        type="invalid"
                                        style={{
                                          display: "block",
                                        }}
                                      >
                                        {errors.voucher_descriptions &&
                                          errors.voucher_descriptions[index]
                                            ?.quantity}
                                      </Form.Control.Feedback>
                                    )}
                                </td>
                                <td style={{ borderWidth: "1px" }}>
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      onKeyPress={numberonly}
                                      value={(
                                        row.item_price * row.quantity
                                      ).toFixed(2)}
                                      rows={1}
                                      readOnly
                                      name="Payments"
                                      type="text"
                                    />
                                  ) : (
                                    <Form.Text
                                      className="text11  text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {(row.item_price * row.quantity).toFixed(
                                        2
                                      )}
                                    </Form.Text>
                                  )}
                                </td>

                                {(requestType === "edit" ||
                                  (isCreateInvoice &&
                                    values?.voucher_descriptions.length >
                                      1)) && (
                                  <td className="text-center fs-5 pt-3 ">
                                    <AiOutlineClose
                                      className="text-danger mt-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        removeFields(index, row?.id)
                                      }
                                    />
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            borderWidth: "1px",
                            padding: "1px 4px 4px 150px",
                          }}
                        >
                          {" "}
                          <Row className="d-flex align-items-center  mb-1">
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Item Total
                              </Form.Text>
                            </Col>
                          </Row>
                          {!(
                            requestType === "view" &&
                            !isCreateInvoice &&
                            !values?.has_discounts
                          ) && (
                            <Row
                              style={{ display: "flex", alignItem: "baseline" }}
                            >
                              <Col className="ps-0">
                              {requestType === "view" && <Form.Label className="sizewt" style={{color:"#454056",opacity:"0.5"}}>Apply Discount</Form.Label>}
                               {requestType !== 'view' && (
                                <Form.Group controlId="applyDIS" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                    checked={applyDIS}
                                    onChange={() => setApplyDIS(!applyDIS)}
                                    type="checkbox"
                                    label="Apply Discount"
                                  />
                                </Form.Group>
                               )}
                              </Col>
                              {applyDIS && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div className="d-flex flex-row sizewt align-items-center">
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      maxLength={3}
                                      type="number"
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      value={values?.has_discounts}
                                      // onChange={(e) => {
                                      //   setFieldValue(
                                      //     "has_discounts",
                                      //     e.target.value
                                      //   );
                                      // }}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_discounts",
                                          nonNegativeValue
                                        );
                                      }}
                                      name="disctval"
                                      style={{
                                        border: "1px solid #666276",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "60px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign: "end",
                                        borderRadius: "6px",
                                      }}
                                    />
                                  ):(
                                   
                                <Form.Text className="text-muted mt-0" style={{ fontSize: "15px",}}>
                                  {values?.has_discounts}
                                 </Form.Text>
                                  )}
                                  </div>
                                  <div
                                    className="d-flex align-items-center flex-row sizewt pb-1"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;=&nbsp;
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                          {applyDIS && (
                            <Row
                              className="d-flex align-items-center mb-1"
                              style={{ marginTop: "-3px" }}
                            >
                              <Col className="px-0">
                                <Form.Text className="text111 text-start">
                                  Net Total
                                </Form.Text>
                              </Col>
                            </Row>
                          )}
                          {!(
                            requestType === "view" &&
                            !isCreateInvoice &&
                            !values?.has_gst
                          ) && (
                            <Row
                              style={{ display: "flex", alignItem: "baseline" }}
                            >
                              <Col className="ps-0">
                              {requestType === "view" && <Form.Label className="sizewt" style={{color:"#454056",opacity:"0.5"}}>Apply GST</Form.Label>}
                              {
                                requestType !== "view" && (
                                  <Form.Group controlId="ApplyGST" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    checked={applyGST}
                                    onChange={() => setApplyGST(!applyGST)}
                                    type="checkbox"
                                    label="Apply GST"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                  />
                                </Form.Group>
                                )
                              }
                              </Col>
                              {applyGST && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div className="d-flex flex-row sizewt align-items-center">
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      name="has_gst"
                                      maxLength={5}
                                      type="number"
                                      value={values?.has_gst}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_gst",
                                          nonNegativeValue
                                        );
                                      }}
                                      style={{
                                        border: "1px solid #666276",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "60px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign: "end",
                                        borderRadius: "6px",
                                      }}
                                    />
                                  ):(
                                    <Form.Text className="text-muted mt-0" style={{ fontSize: "15px",}}>
                                  {values?.has_gst}
                                 </Form.Text>
                                  )}
                                  </div>
                                  <div
                                    className="d-flex align-items-center  flex-row sizewt pb-1"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;=&nbsp;
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                          {/* {!(
                            requestType === "view" &&
                            !isCreateInvoice &&
                            !values?.has_gst
                          ) && (
                            <Row
                              style={{ display: "flex", alignItem: "baseline" }}
                            >
                              <Col className="ps-0">
                                <Form.Group
                                  controlId="additionallDiscount"
                                  md="auto"
                                >
                                  {additionallDiscount ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <FloatingLabel
                                        controlId="floatingAdditionDiscount"
                                        label={
                                          <span
                                            style={{
                                              margin: "-0.6rem 0rem!important",
                                              position: "relative",
                                            }}
                                          >
                                            Additional Discount
                                          </span>
                                        }
                                        style={{
                                          margin: "-6px 0px !important",
                                        }}
                                      >
                                        <Form.Control
                                          size="sm"
                                          type="text"
                                          placeholder="Additional Discount"
                                          style={{ height: "16px" }}
                                          value={values?.special_discount}
                                          readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                            console.log("nonNegativeValue from special",nonNegativeValue)
                                        setFieldValue(
                                          "special_discount",
                                          nonNegativeValue
                                        );
                                      }}
                                        />
                                      </FloatingLabel>
                                      <Form.Control
                                        className=" shadow-none sizewt flex-row align-items-center"
                                        maxLength={3}
                                        type="number"
                                        readOnly={
                                          !(
                                            requestType === "edit" ||
                                            isCreateInvoice
                                          )
                                        }
                                        // value={values?.has_discounts}
                                        // onChange={(e) => {
                                        //   const inputValue = e.target.value;
                                        //   const nonNegativeValue =
                                        //     parseFloat(inputValue) < 0
                                        //       ? 0
                                        //       : inputValue;
                                        //   setFieldValue(
                                        //     "has_discounts",
                                        //     nonNegativeValue
                                        //   );
                                        // }}
                                        name="disctval"
                                        style={{
                                          border: "1px solid #d5d2dc",
                                          right: "5px",
                                          width: "120px",
                                          height: "38px",
                                          fontSize: "14px",
                                          textAlign: "end",
                                          borderRadius: "6px",
                                        }}
                                      />
                                      {console.log("values?.special_discount",values?.special_discount)}
                                    </div>
                                  ) : (
                                    <Form.Check
                                      className="sizewt text-start"
                                      checked={additionallDiscount}
                                      onChange={() =>
                                        setAdditionalDiscount(
                                          !additionallDiscount
                                        )
                                      }
                                      type="checkbox"
                                      label="Additional Discount"
                                      disabled={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                    />
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          )} */}
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1
                              ? 2
                              : requestType === "edit"
                              ? 2
                              : 1
                          }
                          style={{
                            borderWidth: "1px",
                            verticalAlign: "top",
                            paddingTop: "6px",
                          }}
                        >
                          <Row className="mb-1">
                            <Col className="text-start">
                              <Form.Text className="sizewt">
                                {`₹${total.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                          <Row
                            style={{ marginTop: applyGST ? "3px" : "3px" }}
                            className={`${applyGST ? "" : ""}`}
                          >
                            <Col className="text-start">
                              {applyDIS ? (
                                <Form.Text className="sizewt">
                                  {`₹${applieddiscount.toFixed(2)}`}
                                </Form.Text>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="text-start"
                              style={{ marginTop: applyDIS ? "6px" : "0" }}
                            >
                              {applyDIS && (
                                <Form.Text className="sizewt">
                                  {`₹${netTotal}`}
                                </Form.Text>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="text-start"
                              style={{ marginTop: applyDIS ? "6px" : "32px" }}
                            >
                              {applyGST ? (
                                <Form.Text className="sizewt">
                                  {`₹${appliedgst.toFixed(2)}`}
                                </Form.Text>
                              ) : null}
                            </Col>
                          </Row>{" "}
                          {/* <Row>
                            <Col
                              className="text-start"
                              style={{ marginTop: applyDIS ? "32px" : "32px" }}
                            >
                              {additionallDiscount ? (
                                <Form.Text className="sizewt">
                                  {`₹${values?.special_discount}`}
                                </Form.Text>
                              ) : null}
                            </Col>
                          </Row>{" "} */}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{ borderWidth: "1px", paddingLeft: "145px" }}
                        >
                          <Row className="d-flex align-items-center">
                            <Col className="px-0">
                              <Form.Text
                                className="total1"
                                style={{ color: "#666276" }}
                              >
                                Total
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1
                              ? 2
                              : requestType === "edit"
                              ? 2
                              : 1
                          }
                          style={{ borderWidth: "1px" }}
                        >
                          <Row className="">
                            <Col className="text-start">
                              <Form.Text
                                className="total1 "
                                style={{ color: "#666276", textAlign: "start" }}
                              >
                                {`₹${parseFloat(discountVal).toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Row className="mt-1 mb-2">
                    <Col sm={6} className="px-0">
                      <div style={{ maxWidth: "300px" }}>
                        <div className="d-flex mb-1">
                          <Form.Text className="text111 text-start p-0">
                            Payment Details:
                          </Form.Text>
                          {(requestType === "edit" || isCreateInvoice) && (
                            <Button
                              // size="sm"
                              onClick={() => setPaymentDetailsEditable(true)}
                              variant="light"
                              className="text-primary"
                              style={{ padding: "5px 20px" }}
                            >
                              <RiPencilFill />
                            </Button>
                          )}
                        </div>

                        {filterPaymentDetails
                          ?.sort((a, b) => b?.mode - a?.mode)
                          ?.map((payment, index, arr) => {
                            // Check if the current payment mode is selected
                            const isChecked = values.payment_Details
                              ?.map((row) => row?.mode)
                              ?.includes(payment?.mode);
                            const isLastInList = index === arr.length - 1;
                            const showBorderBottom =
                              isChecked ||
                              (values.payment_Details?.length === 1 &&
                                isChecked);

                            return (
                              <div
                                key={payment?.mode}
                                className="d-flex flex-column text-start"
                              >
                                {/* Checkbox */}
                                <Form.Check
                                  label={
                                    // isChecked
                                    //   ? ""
                                    //   :
                                    capitalizeWords(payment?.mode)
                                  } // Hide label when checked
                                  checked={isChecked}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("payment_Details", [
                                        ...values.payment_Details,
                                        payment,
                                      ]);
                                    } else {
                                      setFieldValue(
                                        "payment_Details",
                                        values.payment_Details?.filter(
                                          (row) => row?.mode !== payment?.mode
                                        )
                                      );
                                    }
                                  }}
                                  type="checkbox"
                                  disabled={
                                    !(requestType === "edit" || isCreateInvoice)
                                  }
                                  className="me-2"
                                  style={{ marginBottom: "0px" }}
                                />

                                {/* Payment Details Inline */}
                                {isChecked && (
                                  <div
                                    style={{
                                      borderBottom:
                                        showBorderBottom && !isLastInList
                                          ? "1px solid #efecf3"
                                          : "none",
                                      paddingBottom:
                                        showBorderBottom && !isLastInList
                                          ? "7px"
                                          : "0",
                                      marginLeft: "1.4rem",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    {payment?.mode === "bank" && (
                                      <>
                                        <Form.Text className="text4 mt-0">
                                          <b>Bank Name:</b> {payment?.bank_name}
                                        </Form.Text>
                                        <Form.Text className="text4 mt-0">
                                          <b>Account Name:</b>{" "}
                                          {payment?.accountName}
                                        </Form.Text>
                                        <Form.Text className="text4 mt-0">
                                          <b>Account Number:</b>{" "}
                                          {payment?.accountNumber}
                                        </Form.Text>
                                        <Form.Text className="text4 mt-0">
                                          <b>IFSC code:</b> {payment?.ifscCode}
                                        </Form.Text>
                                        {payment?.swiftCode && (
                                          <Form.Text className="text4 mt-0">
                                            <b>Swift Code:</b>{" "}
                                            {payment?.swiftCode}
                                          </Form.Text>
                                        )}
                                      </>
                                    )}
                                    {/* UPI and Paypal Details */}
                                    {(payment?.mode === "UPI" ||
                                      payment?.mode === "Paypal") && (
                                      <>
                                        <Form.Text className="text4 mt-0 text-start">
                                          <b>{payment?.mode} Id:</b>{" "}
                                          {payment?.paymentID}
                                        </Form.Text>
                                      </>
                                    )}
                                    {/* Other Details */}
                                    {payment?.mode === "other" && (
                                      <>
                                        <Form.Text className="text4 mt-0">
                                          <b>Title:</b> {payment?.title}
                                        </Form.Text>
                                        <Form.Text className="text4 mt-0">
                                          <b>Description:</b>{" "}
                                          {payment?.discription}
                                        </Form.Text>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}

                        {/* Error Feedback */}
                        {touched.payment_Details && errors.payment_Details && (
                          <Form.Control.Feedback
                            className="d-block mt-0"
                            type="invalid"
                          >
                            {errors?.payment_Details}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>

                    <Col sm={6} className="px-0">
                      {/* <InputGroup className=""> */}
                      <Form.Text className="text111 text-start p-0 my-1">
                        Other Details:
                      </Form.Text>
                      <AutoComplete
                        wrapperClassName={isCreateInvoice ? "w-100" : "w-100"}
                        requestType="bills"
                        searchingFor="other_info"
                        callbackFieldValue={(e) =>
                          setFieldValue("other_info", e.target.value)
                        }
                        fieldValue={values.other_info}
                        as="textarea"
                        rows={3}
                        className="w-100 rounded"
                        name="other_info"
                        id="other_info"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Other Details"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                        token={userInfo?.token}
                      />
                      {/* </InputGroup> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {!showTemplate && (
            <Row className="position-sticky bottom-0 p-2 mt-5 mb-3">
              {requestType === "edit" || isCreateInvoice ? (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">Click to add row</Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "99%", marginLeft: "5px" }}
                        onClick={addFields}
                      >
                        Add Row
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all invoices
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: "99%", marginRight: "8px!important" }}
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to change template
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={() => {
                          if (requestType === "edit" || isCreateInvoice) {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/invoiceTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateInvoice,
                              },
                            });
                          }
                        }}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download pdf
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `${values?.invoice_no}.pdf`,
                          });
                        }}
                        variant="primary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print template pdf
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        className="primary w-100"
                        style={{
                          width: "100%",
                          // backgroundColor: "#5e58d1",
                          marginLeft: "3px",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Form>
      </Card>
    </div>
  );
}
