import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modals from "../components/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { handleUpdateProfile } from "../store/Auth/AuthActions";

function UpdateProfile() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const validationSchema = yup.object().shape({
    firstname: yup.string().required("First Name is Required"),
    lastname: yup.string().label("lastname").required("Last Name is Required"),
    email: yup.string().email().required("Email is Required"),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      email: user?.email || "",
    },
  });

  const Submit = async (data) => {
    const { email, firstname, lastname } = data || {};
    // e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE}`,
        {
          user_id: user?.id,
          email: email,
          firstname: firstname,
          lastname: lastname,
          role_id: user?.role_id,
        }
      );

      if (response.status === 200) {
        dispatch(
          handleUpdateProfile({
            ...userInfo,
            user_data: { ...userInfo?.user_data, email, firstname, lastname },
          })
        );
        toast.success("Profile updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/profile");
        }, 3000);
      }
    } catch (error) {
      setShowPopup({
        ...showPopup,
        is: true,
        msg:
          error?.data?.error || error?.response?.data?.error || error?.message,
      });
    }
  };
  return (
    <>
      <Container className="my-4">
        <Form.Text
          className=" d-flex justify-content-center  text-dark mt-0 "
          style={{ fontWeight: "500", fontSize: "22px" }}
        >
          Profile Settings
        </Form.Text>
        <div className="d-flex justify-content-center ">
          <Card
            className=" p-4 pb-0 border-0 bg-light"
            style={{
              width: "100%",
            }}
          >
            <Form onSubmit={handleSubmit(Submit)} className="create-user-form">
              <Form.Label htmlFor="summary" className="mb-0">
                First Name
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  {...register("firstname")}
                  style={{ borderRadius: 8 }}
                />
                <Form.Control.Feedback
                  type={`${errors.firstname?.message && "invalid"}`}
                >
                  {errors.firstname?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Label htmlFor="summary" className="mb-0">
                Last Name
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name="lastname"
                  type="text"
                  placeholder="Last Name"
                  {...register("lastname")}
                  style={{ borderRadius: 8 }}
                />
                <Form.Control.Feedback
                  type={`${errors.lastname?.message && "invalid"}`}
                >
                  {errors.lastname?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Label htmlFor="summary" className="mb-0">
                Email
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  style={{ borderRadius: 8 }}
                />
                <Form.Control.Feedback
                  type={`${errors.email?.message && "invalid"}`}
                >
                  {errors.email?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <div className="d-flex justify-content-end mt-5 ">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip">Click to Submit</Tooltip>
                  }
                >
                  <Button
                    variant="primary"
                    className="btn-sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ height: "21px", width: "21px" }}
                        ></div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </OverlayTrigger>
              </div>
              <ToastContainer />
            </Form>
          </Card>
        </div>
      </Container>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </>
  );
}

export default UpdateProfile;
