import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./Template1.css";
import moment from "moment";
import { toast } from "react-toastify";
import Template1 from "./Templates1";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import Templates2 from "./Templates2";
import Templates3 from "./Templates3";
import { fetchSuggestions } from "../../Utils/AutoComplete";
import { generateInvoiceNumber } from "../../Utils/generateInvoiceNumber";
import PaymentDetails from "../PaymentDetails";
import Templates7 from "./Templates7";
import { FETCH_BANK_SUCCESS } from "../../store/PaymentDetails/BankActionTypes";
import { SET_REFRESH_DATA_TABLE } from "../../store/Theme/ThemeActionTypes";
import Templates8 from "./Templates8";

function SelectTemplete(props) {
  const { state } = useLocation();
  const {
    theme,
    setIsShow = false,
    getInvoiceData = false,
    isShow = false,
  } = props;
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state?.userInfo);
  const { invoices } = useSelector((state) => state?.invoices);
  const { paymentDetails } = useSelector((state) => state.paymentDetails);
  const navigate = useNavigate();
  const isShowTemplate = window.location.pathname.includes("invoiceTemplate");
  const [capitalizedPaidTo, setCapitalizedPaidTo] = useState(true);
  const { id: pathId, requestType: pathRequestType } = useParams();
  const id = props?.id || pathId;
  const requestType = props.requestType || pathRequestType;
  const [projectName, setProjectName] = useState(false);

  const isCreateInvoice =
    window.location.pathname.includes("create-new-invoice") ||
    requestType === "create-duplicate-invoice";
  const [applyGST, setApplyGST] = useState(false);
  const [applyDIS, setApplyDIS] = useState(false);
  const [additionallDiscount, setAdditionalDiscount] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [, setValueUpdater] = useState(null);
  const [autoComplete, setAutoComplete] = useState([]);
  const [editPaymentDetails, setEditPaymentDetails] = useState(false);
  const [showModal, setShowModal] = useState({ is: false, msg: "" });

  // useEffect(() => {
  //   if (invoices?.length === 0 && isShow) {
  //     dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
  //   }
  // }, [invoices]);

  const getPaymentDataData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PAYMENT_DETAILS_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`, // Include the token in the header
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const filterData = res?.data?.data?.map((row) =>
            row?.mode === "bank"
              ? {
                  ...row,
                  confirmAccountNumber: row?.accountNumber,
                  isPrimary: row?.isPrimary === 1 ? true : false,
                }
              : { ...row, isPrimary: row?.isPrimary === 1 ? true : false }
          );
          dispatch({
            type: FETCH_BANK_SUCCESS,
            data: filterData,
          });
        }
        return res;
      })
      .catch((error) => error);
  };
  useEffect(() => {
    const ans = getPaymentDataData();
    console.log("ans", ans);
  }, []);

  // const validationSchema = !projectName
  //   ? yup.object().shape({
  //       paid_to: yup.string().required("Required field."),
  //       address: yup.string().required("Required field."),
  //       date: yup.string().required("Required field."),
  //       voucher_descriptions: yup.array().of(
  //         yup.object().shape({
  //           item_date: yup.string().required("Required field."),
  //           item_description: yup.string().required("Required field."),
  //           item_price: yup.string().required("Required field."),
  //           quantity: yup.string().required("Required field."),
  //         })
  //       ),
  //       payment_Details: yup
  //         .array()
  //         .min(1, "Payment Details required")
  //         .required(),
  //     })
  //   : yup.object().shape({
  //       paid_to: yup.string().required("Required field."),
  //       project_name: yup.string().required("Required field."),
  //       address: yup.string().required("Required field."),
  //       date: yup.string().required("Required field."),
  //       voucher_descriptions: yup.array().of(
  //         yup.object().shape({
  //           item_date: yup.string().required("Required field."),
  //           item_description: yup.string().required("Required field."),
  //           item_price: yup.string().required("Required field."),
  //         })
  //       ),
  //       payment_Details: yup
  //         .array()
  //         .min(1, "Payment Details required")
  //         .required(),
  //     });
  const validationSchema = yup.object().shape({
    paid_to: yup.string().required("Required field."),
    // other_details: yup.string().required("Other details are required"),
    address: yup.string().required("Required field."),
    date: yup.string().required("Required field."),
    // project_name: projectName
    //   ? yup.string().required("Required field.")
    //   : yup.string(),
    //project_name: yup.string().required("Required field."),
    voucher_descriptions: yup.array().of(
      yup.object().shape({
        item_date: yup.string().required("Required field."),
        item_description: yup.string().required("Required field."),
        item_price: yup.string().required("Required field."),
        quantity: yup.string().required("Required field."),
      })
    ),
    payment_Details: yup.array().min(1, "Payment Details required").required(),
  });
  const initialValues =
    state?.isTemplateChange || isShow?.values?.template_id
      ? state?.values || isShow?.values
      : (isCreateInvoice && requestType !== "create-duplicate-invoice") ||
        isShowTemplate
      ? {
          has_gst: 0,
          applyGST: false,
          template_id: theme,
          has_discounts: 0,
          invoice_no: "",
          paid_to: "",
          project_name: "",
          address: "",
          other_info: "",
          special_discount: "",
          user_id: userInfo?.user_data?.id,
          total_amount: "",
          note: "",
          date: moment().format("YYYY-MM-DD"),
          item_unit: "Quantity",
          voucher_descriptions: [
            {
              item_date: moment().format("YYYY-MM-DD"),
              item_description: "",
              item_price: "",
              quantity: "1",
            },
          ],
          payment_Details: [],
        }
      : {
          ...(invoices?.find((item) => item.id === parseInt(id, 10)) || {}),
          removedItems: [],
          template_id: theme === 1 ? 5 : theme,
          itemUnit: "Quantity",
          payment_Details: paymentDetails?.filter((row) =>
            invoices
              ?.find((item) => item.id === parseInt(id, 10))
              ?.payment_detail_id?.includes(row?.id)
          ),
        };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (isCreateInvoice) {
        const allValues = {
          ...formValues,
          voucher_no: generateInvoiceNumber(formValues?.date, "invoice"),
          invoice_no: undefined,
          created_by: formValues?.user_id,
        };
        handleCreateInvoice(allValues);
      } else if (requestType === "edit") {
        const allValues = {
          ...formValues,
          voucher_no: formValues?.invoice_no,
          invoice_no: undefined,
          created_by: formValues?.user_id,
        };
        handleCreateInvoice(allValues);
      }
    },
  });
  useEffect(() => {
    if (isCreateInvoice) {
      const formik_ans = formik.setFieldValue(
        "invoice_no",
        generateInvoiceNumber(formik?.values?.date, "invoice")
      );
      console.log("formik_ans", formik_ans);
    }
  }, [formik?.values?.date]);

  const { ToWords } = require("to-words");
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const handlePaidToChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 255) {
      alert("Bill To length is too long");
      return; // Stop further execution if the input is too long
    }

    const capitalizedValue =
      capitalizedPaidTo && inputValue !== ""
        ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        : inputValue;

    formik.setFieldValue("paid_to", capitalizedValue);

    if (capitalizedPaidTo) {
      setCapitalizedPaidTo(false);
    }
  };
  const handleProjectNameChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      capitalizedPaidTo && inputValue !== ""
        ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        : inputValue;

    formik.setFieldValue("project_name", capitalizedValue);

    if (capitalizedPaidTo) {
      setCapitalizedPaidTo(false);
    }
  };

  const addFields = () => {
    let newfield = {
      item_date: moment().format("YYYY-MM-DD"),
      item_description: "",
      item_price: "",
      quantity: "1",
    };
    if (!isCreateInvoice) {
      newfield = { ...newfield, id: null };
    }
    formik.setFieldValue("voucher_descriptions", [
      ...formik.values.voucher_descriptions,
      newfield,
    ]);
    setValueUpdater(Math.random());
  };

  const removeFields = (index, itemId) => {
    if (requestType === "edit") {
      formik?.setFieldValue("removedItems", [
        ...formik?.values?.removedItems,
        itemId,
      ]);
    }
    if (
      formik.values.voucher_descriptions &&
      Array.isArray(formik.values.voucher_descriptions)
    ) {
      formik.values.voucher_descriptions.splice(index, 1);
    }
    setValueUpdater(Math.random());
  };

  const handleCreateInvoice = (formValues) => {
    setDisableSubmit(true);
    const voucherDescriptions =
      formValues?.voucher_descriptions?.map((item) => ({
        id: requestType === "edit" ? item.id : undefined,
        item_date: item.item_date,
        item_description: item.item_description,
        item_price: projectName
          ? item.item_price * item.quantity
          : item.item_price,
        quantity: projectName ? "1" : item.quantity,
      })) || [];

    const payload = {
      has_gst: applyGST ? formValues?.has_gst : 0,
      template_id: formValues?.template_id,
      has_discounts: applyDIS ? formValues?.has_discounts : 0,
      paid_to: formValues?.paid_to,
      project_name: formValues?.project_name,
      other_info: formValues?.other_info,
      item_unit: formValues?.item_unit,
      address: formValues?.address,
      total_amount: formValues?.total_amount,
      note: formValues?.note || "",
      date: moment(formValues?.date).format("YYYY-MM-DD"),
      voucher_descriptions: voucherDescriptions || [],
      voucher_no: formValues?.voucher_no,
      created_by: formValues?.user_id,
      payment_detail_id:
        formValues?.payment_Details?.map((item) => item.id) || [],
      removedItems: formValues?.removedItems || [],
    };
    if (id && requestType !== "create-duplicate-invoice") {
      payload["id"] = formValues?.id || null;
    }
    const endpoint =
      id && requestType !== "create-duplicate-invoice" ? `/${id}` : ""; // Use the correct endpoint based on requestType

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CREATE_UPDATE_INVOICE}${endpoint}`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          toast.success(
            id && requestType !== "create-duplicate-invoice"
              ? "Invoice updated successfully"
              : "Invoice created successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setTimeout(() => {
            dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
            setDisableSubmit(false);
            if (requestType === "edit") {
              if (setIsShow !== false) {
                setIsShow({
                  open: false,
                  id: "",
                  requestType: "",
                });
              }
            } else {
              navigate("/invoices");
            }
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.message ||
            error?.message,
        });

        return error;
      });
    setProjectName(false);
  };

  const handleClose = () => {
    setShowModal({
      ...showModal,
      is: false,
      msg: "",
    });
  };

  const handleAutoComplete = (e, searching_for, requestType) => {
    const query = e.target.value;
    if (query !== "") {
      fetchSuggestions(query, searching_for, requestType)
        .then((res) => {
          if (res?.status === 200) {
            setAutoComplete(res?.data?.data);
          }
        })
        .catch((error) => {
          setAutoComplete([]);
        });
    } else {
      setAutoComplete([]);
    }
  };
  const renderTooltip = (searching_for) => (
    <Tooltip id="tooltip" style={{ zIndex: 9999 }}>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        <ul
          style={{ listStyleType: "none", textAlign: "left", paddingLeft: 0 }}
        >
          {autoComplete.map((suggestion, index) => (
            <li
              key={index}
              className="my-2"
              style={{
                cursor: "pointer",
                fontWeight: "900px",
                fontSize: "13px",
                margin: "5px",
                transition: "color 0.3s ease",
              }}
              onClick={() => {
                formik?.setFieldValue(`${searching_for}`, suggestion?.value);
                setAutoComplete([]);
              }}
              onMouseEnter={(e) => (e.target.style.color = "#fd5631")}
              onMouseLeave={(e) => (e.target.style.color = "")}
            >
              {suggestion?.value}
            </li>
          ))}
        </ul>
      </div>
    </Tooltip>
  );
  const handleTemplatesChange = (
    needTemplateChange = false,
    tempId = false
  ) => {
    if (needTemplateChange === false && tempId && setIsShow) {
      setIsShow({
        ...isShow,
        needTemplateChange,
        values: undefined,
      });
      formik?.setFieldValue("template_id", tempId);
    }
    if (isShow && setIsShow) {
      setIsShow({
        ...isShow,
        needTemplateChange,
        values: formik?.values,
      });
    }
  };
  const getTemplateView = (id) => {
    let temp;

    switch (id) {
      case 3:
        temp = (
          <Templates2
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
            paymentDetailsEditable={editPaymentDetails}
            setPaymentDetailsEditable={setEditPaymentDetails}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
        break;

      // Added New Template
      case 5:
        temp = (
          <Templates8
            requestType={requestType}
            setProjectName={setProjectName}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handleProjectNameChange={handleProjectNameChange}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
            paymentDetailsEditable={editPaymentDetails}
            setPaymentDetailsEditable={setEditPaymentDetails}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
        break;

      case 4:
        temp = (
          <Templates3
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            additionallDiscount={additionallDiscount}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            setAdditionalDiscount={setAdditionalDiscount}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
            paymentDetailsEditable={editPaymentDetails}
            setPaymentDetailsEditable={setEditPaymentDetails}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
        break;

      // case 7:
      //   temp = (
      //     <Templates4
      //       requestType={requestType}
      //       isCreateInvoice={isCreateInvoice}
      //       applyGST={applyGST}
      //       applyDIS={applyDIS}
      //       toWords={toWords}
      //       addFields={addFields}
      //       setApplyGST={setApplyGST}
      //       setApplyDIS={setApplyDIS}
      //       disableSubmit={disableSubmit}
      //       removeFields={removeFields}
      //       formik={formik}
      //       showTemplate={isShowTemplate}
      //       handlePaidToChange={handlePaidToChange}
      //       handleAutoComplete={handleAutoComplete}
      //       autoComplete={autoComplete}
      //       setAutoComplete={setAutoComplete}
      //       renderTooltip={renderTooltip}
      //       paymentDetailsEditable={editPaymentDetails}
      //       setPaymentDetailsEditable={setEditPaymentDetails}
      //     />
      //   );
      //   break;
      // case 6:
      //   temp = (
      //     <Templates5
      //       requestType={requestType}
      //       isCreateInvoice={isCreateInvoice}
      //       applyGST={applyGST}
      //       applyDIS={applyDIS}
      //       toWords={toWords}
      //       addFields={addFields}
      //       setApplyGST={setApplyGST}
      //       setApplyDIS={setApplyDIS}
      //       disableSubmit={disableSubmit}
      //       removeFields={removeFields}
      //       formik={formik}
      //       showTemplate={isShowTemplate}
      //       handlePaidToChange={handlePaidToChange}
      //       handleAutoComplete={handleAutoComplete}
      //       autoComplete={autoComplete}
      //       setAutoComplete={setAutoComplete}
      //       renderTooltip={renderTooltip}
      //       paymentDetailsEditable={editPaymentDetails}
      //       setPaymentDetailsEditable={setEditPaymentDetails}
      //     />
      //   );
      //   break;
      // case 5:
      //   temp = (
      //     <Templates6
      //       requestType={requestType}
      //       isCreateInvoice={isCreateInvoice}
      //       applyGST={applyGST}
      //       applyDIS={applyDIS}
      //       toWords={toWords}
      //       addFields={addFields}
      //       setApplyGST={setApplyGST}
      //       setApplyDIS={setApplyDIS}
      //       disableSubmit={disableSubmit}
      //       removeFields={removeFields}
      //       formik={formik}
      //       showTemplate={isShowTemplate}
      //       handlePaidToChange={handlePaidToChange}
      //       handleAutoComplete={handleAutoComplete}
      //       autoComplete={autoComplete}
      //       setAutoComplete={setAutoComplete}
      //       renderTooltip={renderTooltip}
      //       paymentDetailsEditable={editPaymentDetails}
      //       setPaymentDetailsEditable={setEditPaymentDetails}
      //     />
      //   );
      //   break;
      // case 6:
      //   temp = (
      //     <Templates7
      //       requestType={requestType}
      //       isCreateInvoice={isCreateInvoice}
      //       applyGST={applyGST}
      //       applyDIS={applyDIS}
      //       toWords={toWords}
      //       addFields={addFields}
      //       setApplyGST={setApplyGST}
      //       setApplyDIS={setApplyDIS}
      //       disableSubmit={disableSubmit}
      //       removeFields={removeFields}
      //       formik={formik}
      //       showTemplate={isShowTemplate}
      //       handlePaidToChange={handlePaidToChange}
      //       handleAutoComplete={handleAutoComplete}
      //       autoComplete={autoComplete}
      //       setAutoComplete={setAutoComplete}
      //       renderTooltip={renderTooltip}
      //       paymentDetailsEditable={editPaymentDetails}
      //       setPaymentDetailsEditable={setEditPaymentDetails}
      //       handleTemplatesChange={handleTemplatesChange}
      //     />
      //   );
      //   break;

      default:
        temp = (
          <Template1
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
            paymentDetailsEditable={editPaymentDetails}
            setPaymentDetailsEditable={setEditPaymentDetails}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
    }

    return temp;
  };
  return (
    <>
      {/* {getTemplateView(parseInt(formik?.values?.template_id, 10))} */}
      {/* {getTemplateView(
        parseInt(
          isShow?.requestType === "view" || isShow?.requestType === undefined ? formik?.values?.template_id : 4,
          10
        )
      )} */}
      {getTemplateView(
        parseInt(
          isShow?.requestType === "view" || state?.templateId === "download"
            ? formik?.values?.template_id
            : 4,
          10
        )
      )}
      <Modal show={showModal?.is} onHide={handleClose} centered>
        <Modal.Header className="d-flex justify-content-center border-0">
          <Modal.Title style={{ color: "black" }}>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {showModal?.msg}{" "}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="closeButton"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={editPaymentDetails}
        onHide={() => setEditPaymentDetails(false)}
        centered
        size="lg"
      >
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
            marginBottom: "-40px",
            maxHeight: "705px",
            // overflowY: "auto",
          }}
        >
          <PaymentDetails
            paymentDetailsEditable={editPaymentDetails}
            setPaymentDetailsEditable={setEditPaymentDetails}
          />{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SelectTemplete;
